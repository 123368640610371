<template>
    <div class="search-results" :class="{ 'expand': mode === 1, 'collapse': mode === 0 }" :style="{ height: currentHeight + 'px' }">
        <div class="search-results__header" @touchstart="handleTouchStart($event)" @touchmove="handleTouchMove" @touchend="handleTouchEnd">
            <div class="search-results__drag" v-if="mode !== 0"></div>
            <img src="@/assets/images/svg/up.svg" class="search-results__drag search-results__drag_up" v-else>
            <picture class="search-results__list-img">
                <source srcset="../../assets/images/svg/list.svg" media="(min-width: 769px)">
                <source srcset="../../assets/images/svg/list_white.svg" media="(max-width: 768px)">
                <img src="@/assets/images/svg/list.svg">
            </picture>
            <h2 class="search-results__heading">Найдено <span>{{ items.length }}</span>
                {{ layer === 'partners' ? 'партнеров' : 'объектов' }}
            </h2>
            <img src="@/assets/images/svg/close_white.svg" class="search-results__close" @click.stop="clearResults">
        </div>
        <ul class="search-results__list">
            <SidebarListItem v-for="(item) in items" :key="item.id" :item="item" />
        </ul>
    </div>
</template>

<script lang="js">
import { mapGetters, mapActions } from 'vuex';
import api from '@/requests';
import SidebarListItem from '@/components/sidebar/SidebarListItem.vue';

export default {
    name: 'SidebarSearchResult',
    components: {
        SidebarListItem
    },
    data() {
        return {
            mode: 1,
            currentHeight: window.innerHeight - 189, // Начальная высота панели
            startPosition: null,
            minHeight: 55, // Минимальная высота панели
            maxHeight: window.innerHeight - 189 // Максимальная высота панели
        }
    },
    async mounted() {
        await this.handleQueryChange();
    },
    watch: {
        async $route(to, from) {
            if (to.query.q !== from.query.q) {
                await this.handleQueryChange();
            }
        }
    },
    methods: {
        async handleQueryChange() {
            const query = this.$route.query.q;
            if (!this.checkingSearchLength(query)) {
                return;
            }

            this.setQuery(query);
            console.log('Query changed:', this.searchQuery);

            await this.fetchItems();
        },
        checkingSearchLength(query) {
            if (query.length < 3) {
                this.$router.push({ path: `/${this.layer}` });
                this.clearQuery();
                return false;
            }

            return true;
        },
        async fetchItems() {
            this.startLoading();

            const items = await api.partners.search(this.searchQuery);
            this.setEntities(items);

            this.stopLoading();
        },
        handleTouchStart(event) {
            this.startPosition = event.touches[0].clientY;
        },
        handleTouchMove(event) {
            event.preventDefault(); // Предотвращаем стандартное поведение браузера

            const currentY = event.touches[0].clientY;
            const distance = this.mode ? currentY - this.startPosition : this.startPosition - currentY;
            const currentHeight = this.mode ? this.maxHeight - distance : this.minHeight + distance;

            if (currentHeight > this.minHeight && currentHeight < this.maxHeight) {
                this.currentHeight = currentHeight;
            }
        },
        handleTouchEnd(event) {
            const middle = this.maxHeight / 2;

            if (this.currentHeight > middle) {
                this.mode = 1;
                this.currentHeight = this.maxHeight;
            } else {
                this.mode = 0;
                this.currentHeight = this.minHeight;
            }

            this.startPosition = null;
        },
        clearResults() {
            this.$router.push(`/${this.layer}`);
        },
        ...mapActions('search', ['setQuery', 'clearQuery']),
        ...mapActions('loader', ['startLoading', 'stopLoading']),
        ...mapActions('entity', ['setEntities'])
    },
    computed: {
        ...mapGetters('layer', ['layer']),
        ...mapGetters('search', ['searchQuery']),
        ...mapGetters('entity', ['items'])
    }
}
</script>

<style>
.search-results {
  display: flex;
  flex-direction: column;
  transition: height .15s ease-out;
  overflow-y: hidden;
}

.search-results__header {
  background-color: #F2F2F2;
  border-top: 1px solid #BFBFBF;
  border-bottom: 1px solid #BFBFBF;
  padding-left: 8px;
  min-height: 42px;
  display: flex;
  justify-content: left;
  align-items: center;
  position: sticky;
  top: 0px;
  z-index: 1;
}

.search-results__heading {
  font-size: 16px;
  line-height: 22px;
}

.search-results__heading span {
  font-weight: bold;
}

.search-results__list {
  background-color: #fff;
  overflow-y: auto;
  height: calc(100vh - 260px);
}

.search-results__list::-webkit-scrollbar {
  width: 9px;
}

.search-results__list::-webkit-scrollbar-thumb {
  background-color: black;
  border-right: 4px #fff solid;
  background-clip: padding-box;
}

.search-results__list-img {
  width: 26px;
  height: 26px;
  margin-right: 8px;
}

.search-results__list li {
  position: relative;
  padding: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #E5E5E5;
  transition: background-color .3s ease;
  cursor: pointer;
}

.search-results__list .visited {
  background-color: #EFEFEF;
}

.search-results__name {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 5px;
  padding-right: 30px;
}

.search-results__certificate,
.search-results__address,
.search-results__phone {
  font-size: 16px;
  line-height: 20px;

  color: #8C8C8C;
}

.search-results__certificate {
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-bottom: 5px;
}

.search-results__certificate img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.search-results__visibility {
  position: absolute;
  top: 20px;
  right: 20px;
}

.search-results__visibility img {
  width: 20px;
}

.search-results-badge__visibility {
  position: absolute;
  top: 5px;
  right: 20px;
}

.search-results-badge__visibility p {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  color: #8C8C8C;
}

.search-results__drag {
  position: absolute;
  top: 6px;
  left: 50%;
  transform: translateX(-50%);
  width: 22px;
  height: 3px;
  background-color: rgba(255, 255, 255, .7);
  display: none;
}

.search-results__drag_up {
  width: 22px;
  height: 6px;
  background-color: transparent;
}

.search-results__close {
  position: absolute;
  top: 14px;
  right: 10px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: none;
}

@media (max-width: 1023px) {
  .search-results {
    position: fixed;
    bottom: 0;
    width: 100vw;
  }

  .search-results.expand {
    overflow-y: scroll;
  }

  .search-results.collapse {
    height: 55px;
  }

  .search-results__header {
    background-color: #000;
    color: #fff;
    min-height: 56px;
  }

  .search-results__heading {
    font-size: 18px;
  }

  .search-results__list {
    flex-grow: 1;
  }

  .search-results__drag,
  .search-results__close {
    display: block;
  }
}
</style>
